<template>
  <div class="promo">
    <div class="promo__container container">
     <div class="promo__content">
       <h3 v-if="subtitle" class="promo__subtitle" v-html="subtitle" />
       <h1 class="promo__title" v-html="title" />
       <div v-if="text" class="promo__text" v-html="text" />
     </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderSection',
  props: {
    subtitle: String,
    title: String,
    text: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 170px 0 110px;
  background-color: $primary;

  @include breakpoint(0, $lg) {
    padding: 150px 0 100px;
  }

  &__content {
    position: relative;
    z-index: 10;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    color: $light;
    font-weight: 400;
    text-transform: uppercase;

    @include breakpoint(0, $lg) {
      font-size: 18px;
    }

    @include breakpoint(0, $md) {
      font-size: 16px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 50px;
    color: $light;
    font-weight: 400;

    @include breakpoint(0, $lg) {
      font-size: 40px;
    }

    @include breakpoint(0, $md) {
      font-size: 36px;
    }

    br {
      @include breakpoint(0, $lg) {
        display: none;
      }
    }
  }

  &__text {
    font-size: 16px;
    color: $light;
    :deep {
      p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }
}
</style>
